<template>
  <q-form ref="editForm">
    <c-card title="감사계획 상세" class="cardClassDetailForm">
      <template slot="card-button">
        <q-btn-group outline >
          <c-btn
            v-show="editable && !disabled"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="planData"
            :mappingType="mappingType"
            label="LBLSAVE"
            icon="save"
            @beforeAction="saveData"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </template>
      <template slot="card-detail">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            :required="true"
            :editable="editable"
            :disabled="disabled"
            label="감사명"
            name="selfInspectionTitle"
            v-model="planData.selfInspectionTitle">
          </c-text>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :required="true"
            :editable="editable"
            :disabled="disabled"
            type="year"
            label="감사년도"
            name="selfInspectionYear"
            v-model="planData.selfInspectionYear"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
          <c-select
            :required="true"
            codeGroupCd="SELF_INSPECTION_TYPE_CD"
            type="search"
            :disabled="disabled"
            itemText="codeName"
            itemValue="code"
            name="selfInspectionTypeCd"
            label="자체감사종류"
            v-model="planData.selfInspectionTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
          <c-plant
            :required="true"
            :editable="editable"
            :disabled="disabled"
            type="edit"
            name="plantCd"
            v-model="planData.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-2">
          <c-select
            :editable="false"
            type="none"
            :disabled="disabled"
            :comboItems="revisionItems"
            itemText="codeName"
            itemValue="code"
            label="평가항목 개정번호"
            name="sysRevision"
            v-model="planData.sysRevision">
          </c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-card title="감사대상" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-12">
                <c-text
                  :editable="editable"
                  :disabled="disabled"
                  label="감사대상개요"
                  name="remark"
                  v-model="planData.remark">
                </c-text>
              </div>
              <div class="col-4">
                <c-table
                  ref="gridProcess"
                  title="대상공정"
                  tableId="gridProcess"
                  :columns="gridProcess.columns"
                  :data="planData.processList"
                  selection="multiple"
                  rowKey="processCd"
                  :isFullScreen="false"
                  :columnSetting="false"
                  :filtering="false"
                  :usePaging="false"
                  :hideBottom="true"
                  :editable="editable && !disabled"
                  gridHeight="300px"
                >
                  <!-- 버튼 영역 -->
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn v-if="editable && !disabled" icon="add" @btnClicked="addProcess" />
                      <c-btn v-if="editable && !disabled" icon="remove" @btnClicked="removeProcess" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
              <div class="col-4">
                <c-table
                  ref="gridEquip"
                  title="대상설비"
                  tableId="gridEquip"
                  :columns="gridEquip.columns"
                  :data="planData.equipList"
                  selection="multiple"
                  rowKey="equipmentCd"
                  :isFullScreen="false"
                  :columnSetting="false"
                  :filtering="false"
                  :usePaging="false"
                  :hideBottom="true"
                  :editable="editable && !disabled"
                  gridHeight="300px"
                >
                  <!-- 버튼 영역 -->
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn v-if="editable && !disabled" icon="add" @btnClicked="addEquip" />
                      <c-btn v-if="editable && !disabled" icon="remove" @btnClicked="removeEquip" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
              <div class="col-4">
                <c-table
                  ref="gridDept"
                  title="대상부서"
                  tableId="gridDept"
                  :columns="gridDept.columns"
                  :data="planData.deptList"
                  selection="multiple"
                  rowKey="deptCd"
                  :isFullScreen="false"
                  :columnSetting="false"
                  :filtering="false"
                  :usePaging="false"
                  :hideBottom="true"
                  :editable="editable && !disabled"
                  gridHeight="300px"
                >
                  <!-- 버튼 영역 -->
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn v-if="editable && !disabled" icon="add" @btnClicked="addDept" />
                      <c-btn v-if="editable && !disabled" icon="remove" @btnClicked="removeDept" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-card title="감사수행일정" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-6">
                <c-datepicker
                  :required="true"
                  :disabled="disabled"
                  :editable="editable"
                  :range="true"
                  label="감사기간"
                  name="selfInspectionDt"
                  v-model="planData.selfInspectionDt"
                />
              </div>
              <div class="col-12">
                <c-table
                  ref="gridExec"
                  title="일자별 수행내역"
                  tableId="gridExec"
                  :columns="gridExec.columns"
                  :data="planData.execList"
                  selection="multiple"
                  rowKey="selfInspectionExecId"
                  :isFullScreen="false"
                  :columnSetting="false"
                  :filtering="false"
                  :usePaging="false"
                  :editable="editable && !disabled"
                  :hideBottom="true"
                  gridHeight="300px"
                >
                  <!-- 버튼 영역 -->
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn v-if="editable && !disabled" label="추가" icon="add" @btnClicked="addExec" />
                      <c-btn v-if="editable && !disabled" label="제외" icon="remove" @btnClicked="removeExec" />
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
            </template>
          </c-card>
        </div>
      </template>
    </c-card>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'plan-info',
  props: {
    param: {
      type: Object,
      default: () => ({
        selfInspectionResultId: '',
        selfInspectionStatusCd: '',
        sysRevision: '',
      }),
    },
  },
  data() {
    return {
      revisionItems: [],
      planData: {
        selfInspectionResultId: '',  // 자체감사 계획 일련번호
        selfInspectionTitle: '',  // 감사명
        selfInspectionYear: '',  // 감사년도
        selfInspectionTypeCd: null,  // 감사종류
        plantCd: null,  // 해당사업장
        remark: '',  // 감사대상 개요
        selfInspectionStartDt: '',  // 감사기간-시작일
        selfInspectionEndDt: '',  // 감사기간-종료일
        selfInspectionStatusCd: '',  // 감사진행상태-계획중,계획완료,감사완료
        selfInspectionResultOpinion: '',  // 감사원소견
        selfInspectionResultTotalOpinion: '',  // 감사 결과 종합의견
        selfInspectionResultCorrect: '',  // 시정 및 권고 요약
        selfInspectionDt: [],
        sysRevision: '',  // 평가항목 개정번호
        processList: [],
        equipList: [],
        deptList: [],
        execList: [],
        deleteExecList: [],
      },
      gridProcess: {
        columns: [
          {
            name: 'processName',
            field: 'processName',
            label: '상위공정명',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      gridEquip: {
        columns: [
          {
            name: 'equipmentName',
            field: 'equipmentName',
            label: '설비명',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      gridDept: {
        columns: [
          {
            required: true,
            name: 'deptName',
            field: 'deptName',
            label: 'LBLDEPT',
            align: 'left',
            sortable: false,
          },
        ],
        data: [],
      },
      gridExec: {
        columns: [
          {
            required: true,
            name: 'execDt',
            field: 'execDt',
            label: '수행일자',
            align: 'center',
            style: 'width: 120px',
            type: 'date',
            sortable: false,
          },
          {
            required: true,
            name: 'execContent',
            field: 'execContent',
            label: '감사내역',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'execUser',
            field: 'execUser',
            label: '참석자',
            align: 'left',
            style: 'width: 200px',
            type: 'text',
            sortable: false,
          },
        ],
        data: [],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        isFull: false,
        param: {},
        closeCallback: null,
      },
      editable: true,
      getUrl: '',
      insertUrl: '',
      updateUrl: '',
      deleteUrl: '',
      revisionUrl: '',
      saveUrl: transactionConfig.sop.pca.result.info.insert.url,
      mappingType: 'POST',
      isSave: false,
      isSaveEnd: false,
      isdelete: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isOld() {
      return Boolean(this.param.selfInspectionResultId)
    },
    disabled() {
      return Boolean(this.param.selfInspectionStatusCd) && this.param.selfInspectionStatusCd !== 'SISC000001'
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.revisionUrl = selectConfig.sop.pca.item.revs.item;
      this.getUrl = selectConfig.sop.pca.result.info.get.url;
      this.insertUrl = transactionConfig.sop.pca.result.info.insert.url;
      this.updateUrl = transactionConfig.sop.pca.result.info.update.url;
      this.deleteUrl = transactionConfig.sop.pca.result.info.delete.url;
      this.getRevision();
    },
    getRevision() {
      this.$http.url = this.revisionUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.revisionItems = _result.data;
        this.getInfo();
      },);
    },
    getInfo() {
      if (this.param.selfInspectionResultId) { 
        this.$http.url = this.$format(this.getUrl, this.param.selfInspectionResultId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.planData = _result.data;
          this.planData.sysRevision = this.planData.sysRevision + '';
          this.planData.selfInspectionDt = [this.planData.selfInspectionStartDt, this.planData.selfInspectionEndDt];
        },);
      } else {
        this.planData.sysRevision = this.revisionItems[0].code;
        this.param.sysRevision = this.revisionItems[0].code;
      }
    },
    saveData() {
      if (this.param.selfInspectionResultId) {
        this.saveUrl = this.updateUrl;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.mappingType = 'POST';
        this.planData.selfInspectionStatusCd = 'SISC000001'; // 계획중
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          if (this.$comm.validTable(this.gridExec.columns, this.planData.execList)) {
            if (this.$comm.validTable(this.gridDept.columns, this.planData.deptList)) {
              window.getApp.$emit('CONFIRM', {
                title: 'LBLCONFIRM',
                message: "저장하시겠습니까?",
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.planData.regUserId = this.$store.getters.user.userId;
                  this.planData.chgUserId = this.$store.getters.user.userId;
                  this.planData.selfInspectionStartDt = this.planData.selfInspectionDt[0];
                  this.planData.selfInspectionEndDt = this.planData.selfInspectionDt[1];
                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            }
          }
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.param.selfInspectionResultId = result.data;
      if (this.mappingType === 'POST') {
        this.$emit("saveCallback", {selfInspectionResultId: result.data, selfInspectionStatusCd: 'SISC000001', sysRevision: this.planData.sysRevision + ''})
      }
      this.getInfo();
    },
    addProcess() {
      this.popupOptions.title = '공정 검색';
      this.popupOptions.param = {
        type: 'multiple',
        processLevelCd: '10'
      };
      this.popupOptions.target = () => import(`${'@/pages/common/process/processPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeProcess;
    },
    closeProcess(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.planData.processList, { processCd: item.processCd }) === -1) {
            this.planData.processList.push({
              selfInspectionResultId: this.param.selfInspectionResultId,  // 자체감사 계획 일련번호
              processCd: item.processCd,  // 대상공정코드
              processName: item.processName,
              edtiFlag: 'C',
            })
          }
        })
      }
    },
    removeProcess() {
      let selectData = this.$refs['gridProcess'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          this.planData.processList = this.$_.reject(
            this.planData.processList,
            item
          );
        });
        this.$refs['gridProcess'].$refs['compo-table'].clearSelection();
      }
    },
    addEquip() {
      this.popupOptions.title = '설비 검색';
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.planData.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/equip/equipPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeEquipmenet;
    },
    closeEquipmenet(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.planData.equipList, { equipmentCd: item.equipmentCd }) === -1) {
            this.planData.equipList.push({
              selfInspectionResultId: this.param.selfInspectionResultId,  // 자체감사 계획 일련번호
              equipmentCd: item.equipmentCd,
              equipmentName: item.equipmentName,
              editFlag: 'C',
            })
          }
        })
      }
    },
    removeEquip() {
      let selectData = this.$refs['gridEquip'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          this.planData.equipList = this.$_.reject(
            this.planData.equipList,
            item
          );
        });
        this.$refs['gridEquip'].$refs['compo-table'].clearSelection();
      }
    },
    addDept() {
      this.popupOptions.title = '부서 검색';
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.planData.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/common/dept/deptMultiPop.vue'}`);
      this.popupOptions.width = '50%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeDept;
    },
    closeDept(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.planData.deptList, { deptCd: item.deptCd }) === -1) {
            this.planData.deptList.push({
              selfInspectionResultId: this.param.selfInspectionResultId,  // 자체감사 계획 일련번호
              deptCd: item.deptCd,
              deptName: item.deptName,
              editFlag: 'C',
            })
          }
        })
      }
    },
    removeDept() {
      let selectData = this.$refs['gridDept'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          this.planData.deptList = this.$_.reject(
            this.planData.deptList,
            item
          );
        });
        this.$refs['gridEquip'].$refs['compo-table'].clearSelection();
      }
    },
    addExec() {
      this.planData.execList.push({
        editFlag: 'C',
        selfInspectionResultId: this.param.selfInspectionResultId,  // 자체감사 계획 일련번호
        selfInspectionExecId: uid(),  // 일자별 수행내역 일련번호
        execDt: this.$comm.getToday(),  // 수행일자
        execContent: '',  // 감사내역
        execUser: '',  // 참석자
      })
    },
    removeExec() {
      let selectData = this.$refs['gridExec'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.planData.deleteExecList) {
            this.planData.deleteExecList = [];
          }
          if (
            this.$_.findIndex(this.planData.deleteExecList, {
              selfInspectionExecId: item.selfInspectionExecId,
            }) === -1 &&
            item.editFlag !== "C"
          ) {
            this.planData.deleteExecList.push(item);
          }
          this.planData.execList = this.$_.reject(
            this.planData.execList,
            item
          );
        });
        this.$refs['gridExec'].$refs['compo-table'].clearSelection();
      }
    },
  }
};
</script>
